
import AppHeader from "@/components/AppHeader.vue";
import Error from "@/components/Error.vue";
import ApplicationModal from "@/components/modals/ApplicationModal/ApplicationModal.vue";
import VDModal from "@/components/modals/VDModal";
import Page from "@/components/Page.vue";
import useError from "@/composables/useError";
import usePluralFilter from "@/composables/usePluralFilter";
import { ApplicationType, useGetApplicationsQuery } from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { FilterMatchMode } from "primevue/api";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

// Query
gql`
  query getApplications {
    applications {
      applicationId
      name
      roles {
        name
      }
    }
  }
`;

export default defineComponent({
  name: "Applications",
  components: { AppHeader, Page, Error, ApplicationModal },
  setup() {
    const applicationModal = ref<VDModal<void> | null>(null);

    const { result, error, loading } = useGetApplicationsQuery();
    const router = useRouter();

    function onRowClick(data: ApplicationType) {
      if (data.applicationId && data.applicationId != null)
        router.push({
          name: "application",
          params: {
            applicationId: data.applicationId ?? "",
          },
          query: {
            title: data.name,
          },
        });
    }

    return {
      applicationModal,
      applications: useResult(result),
      error: useError(error),
      loading,
      filters: useFilters(),
      onRowClick,
      usePluralFilter,
    };
  },
});

function useFilters() {
  return ref({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } });
}
